import { Pagination, PaginationItem, Stack } from '@mui/material'
import React, { useCallback } from 'react'


const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};




const Paginations = ({ totalPages, currentPage, onPageChange,disabled }) => {
  // const debouncedOnPageChange = useCallback(
  //   debounce((event, page) => {
  //     onPageChange(page);
  //   }, 300), 
  //   [onPageChange]
  // );
  const debouncedOnPageChange = useCallback(
    debounce((event, value) => {
      if (value !== currentPage) {
        onPageChange(value); 
      }
    }, 300),
    [onPageChange, currentPage]
  );
 
  return (
    <div>
        <Stack spacing={2} sx={{marginTop:'10px'}}>
        <Pagination
        count={totalPages}
        page={currentPage}
        onChange={debouncedOnPageChange}
        disabled={disabled}
        

    shape="rounded"
    renderItem={(item) => (
      <PaginationItem
      
        {...item}
        sx={{
          '&.Mui-selected': {
            backgroundColor: '#065F46', 
            color: '#FFF', 
          },
        }}
        components={{
          previous: () => <span>Previous</span>,
          next: () => <span>Next</span>,
        }}
      />
    )}
  />
</Stack>
    </div>
  )
}

export default Paginations