import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import backImg from "../../common/assests/backImg.png";
import OTPInput from "react-otp-input";
import './forget.css';
import CustomBtn from "../../common/customButton/CustomBtn";
import { handleOtpApi, handleResendOtpApi } from "../../api";
import { toast } from "react-toastify";

const OtpModal = ({ open, onClose, onNext, onBack,refId}) => {

  // console.log("aaaaaaa",refId);
  
  // const [OTP, setOTP] = useState(NaN);
  const handleBack = () => {};

  const [otp,setOtp]=useState(NaN);

  const handleOtpInput=async()=>{
    if(otp.length<4){
      toast.warning("Enter a valid otp")
      return
    }
      try {
        const response=await  handleOtpApi({refId,otp})
        toast.success(response.message)
        setOtp("")
        onNext();
      } catch (error) {
        toast.error(error.message);
      }
  }

  const handleResendOtp=async()=>{
    try {
      const response=await  handleResendOtpApi(refId)
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message);
    }
  }


  const otpBgHandle=(props)=>{
      const {value}=props;
      return (
      <input
              {...props} 
              className="responsive-input"
              style={{
                width: "68px",
                height: "68px",
                borderRadius: "20px",
                border: "1.4px solid rgba(54, 43, 36, 0.2)",
                fontSize: "1.8rem",
                textAlign: "center",
                margin: "0 8px",
                outline: "none",
                backgroundColor: value ? "#065F46" : "#FFFFFF",
                color: value ? "#FFFFFF" : "black",
              }}
            />
            )
  }



  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      // maxWidth="xs"
     
      
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "32px",
          background: "#FFF",
          //   padding: '5px',
          //   width: "100%",
          width: "100%",
          maxWidth:'480px',
          boxSizing: "border-box",
          marginRight: {
            xs: "30px",
            sm: "30px",
            md: "30px",
            lg: "35px",
            xl: "45px",
            
          },
          '@media (min-width: 1600px)': {
        marginRight: "32px",
      },
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogContent>
        <Box sx={{marginTop:'15px',paddingLeft:'10px'}}>
          <Typography
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "20%",
              alignItems: "center",
              cursor: "pointer",
               gap:'10px'
            }}
            onClick={onBack}
          >
            <img
              src={backImg}
              alt="backImg"
              style={{ height: "29px", width: "29px" }}
            />
            <Typography
              sx={{ color: "#065F46", fontWeight: "500", fontSize: "20px" }}
            >
              Back
            </Typography>
          </Typography>
        </Box>
      </DialogContent>

      <DialogTitle
        sx={{
          
          fontSize: {sm:'24px' },
          fontWeight: "700",
          fontFamily: "Heebo",
          paddingLeft:'50px'
        }}
      >
        Enter Verification Code
      </DialogTitle>
      <DialogTitle
        sx={{
          marginTop: "-27px",
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Heebo",
          opacity: "0.5",
          paddingLeft:'50px'
        }}
      >
        We have sent a verification code to your email
      </DialogTitle>

      <DialogContent sx={{ display: "flex", justifyContent: "center" ,marginTop:'20px'}}>
        {/* <TextField
          autoFocus
          margin="dense"
          id="otp"
          label="OTP"
          type="text"
          fullWidth
          variant="standard"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        /> */}
        <OTPInput
          value={otp}
          onChange={setOtp}
          autoFocus
          OTPLength={4}
          otpType="number"
          disabled={false}
          renderInput={otpBgHandle}
        />
      </DialogContent>

      <DialogContent>
        <Typography sx={{ textAlign: "center", fontSize: "16px" }}>
          <span style={{ opacity: "0.5", fontWeight: "400" }}>
            Didn’t Receive the Code
          </span>{" "}
          <span
          onClick={handleResendOtp}
            style={{
              color: "#065F46",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Heebo",
              cursor:'pointer'
            }}
          >
            Resend
          </span>
        </Typography>
      </DialogContent>

      <DialogActions>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          style={{ marginBottom: "20px" }}
        >
          {/* <Button
            onClick={onNext}
            sx={{
              width: "100%",
              maxWidth: "373px",
              height: "60px",
              flexShrink: 0,
              borderRadius: "30px",
              background: "linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)",
              boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Heebo",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "13px",
              textTransform: "none",
              marginTop: "10px",
            }}
          >
            Next
          </Button> */}

          <CustomBtn onClick={handleOtpInput} text="Next"/>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default OtpModal;
