import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  IconButton,
  Button,
  TableHead,
  Modal,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Pagination,
  Stack,
  PaginationItem,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import EyeIcon from "../../common/assests/eyegreen.png";
import Adminimg from "../../common/assests/adminimg.png";
import CrossIcon from "../../common/assests/croosicon.png";
import SearchItem from "../../common/assests/search.svg";
import SearchIcon from "@mui/icons-material/Search";
import Paginations from "../../common/pagination/Paginations";
import { allOrderListApi, OrderDetailsApi } from "../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../common/loader/Loader";
// import './Orders.css';


const AllOrder = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const { id } = useParams();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:480px)");
  const isMediumScreen = useMediaQuery("(max-width:768px)");
  
  const [allOrderData,setAllOrderData]=useState([])
  const [totalPages,setTotalPages]=useState()
  const [currentPage,setCurrentPage]=useState(1)
  const [loading,setLoading]=useState(false)



  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleAllOrderList=async()=>{
    try {
    
      setLoading(true);
      const response = await allOrderListApi({token,currentPage,id:id});
      console.log("allorder.",response);
// setTest(response.orders[0]._id)
      // toast.success(response.message)
      setAllOrderData(response?.orders|| []);
      // console.log("usr",response);
      // setLimit(response?.limit)
      setCurrentPage(response?.page)
      setTotalPages(response?.totalPages)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  }

  useEffect(() => {
    handleAllOrderList();
    // handleOrderDetail();
  }, []);




  // const handleOrderDetail=async()=>{
  //   try {
    
  //     setLoading(true);
  //     const response = await OrderDetailsApi({token,id:test});
  //     console.log("orderdetails.",response);
      
      // setAllOrderData(response?.users|| []);
      // console.log("usr",response);
      // setLimit(response?.limit)
      // setCurrentPage(response?.page)
      // setTotalPages(response?.totalPages)
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log("error", error);
  //   }
  // }





  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const [rowss, setRowss] = useState([
    {
      id: 1,
      userImage: Adminimg,
      productName: "XYZ",
      catgeory: "ABC",
      quantity: "2",
      regular: "$100",
      itemtotal: "$20",
      discount: "$20",
    },
  ]);
  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };


  const tableHeaders = [
    "Order By",
    "Business Name",
    "Address",
    "Total Price",
    "Quantity",
    "Payment Status",
    "Order Status",
    "Action"
  ];


  return (
    <div>
      {loading ? (
        <Loader/>
      ):(

    <>
      <Box sx={{ marginTop: "40px", marginBottom: "30px" }}>
        <TableContainer
          component={Paper}
          sx={{ padding: "26px", borderRadius: "24px" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap:"wrap",
              gap:'10px',
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{ fontSize:{xs:'16px',sm:'20px'}, fontWeight: "600", color: "#362B24" }}
            >
              Order List
            </Typography>
            <TextField
          
           placeholder="Search..."
           sx={{
             width: { xs: '60%', sm: 220 }, 
             height: {xs:45,sm:42},
             flexShrink: 0,
             borderRadius: "33px",
             background: "#EBEBEB",
             
             border: "none", 
             "& .MuiOutlinedInput-root": {
               borderRadius: "33px",
               height: "100%",
               paddingRight: "10px",
               outline: "none",
               border: "none", 
             },
             "& .MuiOutlinedInput-notchedOutline": {
               border: "none", 
             },
           }}
           InputProps={{
             startAdornment: (
               <InputAdornment position="start">
                  <img src={SearchItem}
                   sx={{ color: "#000", opacity: "0.35" }}
                 />
               </InputAdornment>
             ),
             style: { height: "100%" },
           }}
         />
          </Box>

          <Table sx={{ minWidth: 650 }} aria-label="custom table">
            <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableCell key={index} className="Orders-tbl-cell allvender">
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            <TableBody>
              {allOrderData.length >0 ? (

                allOrderData.map((order) => (
                  // {order _id }
                  <TableRow
                    key={order?._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <img
                          src={row.userImage}
                          alt={row.userName}
                          style={{
                            height: "35px",
                            width: "35px",
                            marginRight: "8px",
                            borderRadius: "50%",
                          }}
                        /> */}
                        <Typography>{order?.userName||"-"}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <img
                          src={row.businessImage}
                          alt={row.businessName}
                          style={{
                            height: "35px",
                            width: "35px",
                            marginRight: "8px",
                            borderRadius: "50%",
                          }}
                        /> */}
                        <Typography>{order?.businessName||"-"}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                    {order?.address 
                        ? [
                            order.address.address || "", 
                            order.address.city || "",
                            order.address.state || "",
                            order.address.country || "",
                            order.address.zipcode || ""
                          ]
                            .filter(Boolean)  
                            .join(", ")      
                        : "-"                
                      }
                    </TableCell>
                    <TableCell> {`$${order?.totalAmount || "-"}`}</TableCell>

                    {order?.items?.map((item, index) => (
                          <TableCell key={index}>
                            {item?.quantity || "-"}
                          </TableCell>
                        ))}

                    <TableCell>{order?.paymentStatus||"-"}</TableCell>
                    <TableCell>{order?.status||"-"}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleClickOpen(order)}>
                        <img
                          src={EyeIcon}
                          alt="viewicon"
                          style={{
                            height: isSmallScreen ? "35px" : "45px",
                            width: isSmallScreen ? "35px" : "45px",
                          }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ):(
                <TableRow align='center'>
                <TableCell colSpan={tableHeaders.length}>
                  <Typography align="center">No Data Found</Typography>
                </TableCell>
              </TableRow>
              )}
            </TableBody>
          </Table>

          <Paginations totalPages={totalPages} currentPage={currentPage} disabled={loading} onPageChange={handlePageChange}/>
        </TableContainer>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            maxHeight: "80vh",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // maxWidth: '630px',
            maxWidth: '750px',
            // maxWidth: "874px",
            width: "90%",
            bgcolor: "#F5F5F5",
            borderRadius: "32px",
            boxShadow: 24,

            overflowY: "auto",
            p: 4,
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "@media (min-width: 1600px)": {
              width: "90vw",
            },
            "@media (max-width: 1400px)": {
              width: "80vw",
            },
            "@media (max-width: 1200px)": {
              width: "60vw",
            },
            "@media (max-width: 1024px)": {
              width: "60vw",
            },
            "@media (max-width: 767px)": {
              width: "80vw",
            },
            "@media (max-width: 576px)": {
              width: "90vw",
            },
            "@media (max-width: 480px)": {
              width: "95vw",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                marginBottom: "16px",
                color: "#362B24",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              Order Details
            </Typography>

            <IconButton>
              <img
                src={CrossIcon}
                alt="img"
                onClick={handleClose}
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
              />
            </IconButton>
          </Box>
          {selectedRow && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "#362B24",
                    }}
                  >
                    Order By
                  </Typography>
                  <Typography sx={{ marginTop: "15px" }}>
                    <img
                      src={selectedRow.userImage}
                      alt="User"
                      style={{
                        width: "42px",
                        height: "42px",
                        marginRight: "5px",
                      }}
                    />
                    <strong> {selectedRow.userName}</strong>
                  </Typography>
                </Box>
              </Box>
              <br />
              <Box
                sx={{
                  display: "grid",
                //   gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
                gridTemplateColumns: "repeat(auto-fit, minmax(142px, 1fr))",
                  gap: 3,
                }}
              >
                <Box>
                  <Typography
                    sx={{ fontWeight: "400", opacity: "0.6", color: "#362B24" }}
                  >
                    Order Date
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>19 July 2024</strong>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ fontWeight: "400", opacity: "0.6", color: "#362B24" }}
                  >
                    Order ID
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>#895421UH7D45</strong>
                  </Typography>
                </Box>
                <Box>
               
                  <Typography
                    sx={{ fontWeight: "400", opacity: "0.6", color: "#362B24" }}
                  >
                    Order Status
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>Pending</strong>
                  </Typography>
                </Box>
                 
                
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      opacity: "0.6",
                      color: "#362B24",
                      fontSize: "15px",
                    }}
                  >
                    Payment Status
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>Completed</strong>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ fontWeight: "400", opacity: "0.6", color: "#362B24" }}
                  >
                    Card
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>Visa</strong>
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{ fontWeight: "400", opacity: "0.6", color: "#362B24" }}
                  >
                    Total Price
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>$45.00</strong>
                  </Typography>
                </Box>
                
                <Box>
                  <Typography
                    sx={{ fontWeight: "400", opacity: "0.6", color: "#362B24" }}
                  >
                    Shipping Address
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>{"ABcd"}</strong>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ fontWeight: "400", opacity: "0.6", color: "#362B24" }}
                  >
                    Seller Name
                  </Typography>
                  <Typography variant="body2" sx={{ marginTop: "8px" }}>
                    <img
                      src={selectedRow.userImage}
                      alt="User"
                      style={{
                        width: "42px",
                        height: "42px",
                        marginRight: "5px",
                      }}
                    />
                    <strong> {selectedRow.userName}</strong>
                  </Typography>
                </Box>
               
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",

                  marginTop: "20px",
                }}
              >
                 

               
              </Box>

              {/* tble */}
              <Box sx={{ marginTop: "40px", marginBottom: "30px" }}>
                <TableContainer
                  component={Paper}
                  sx={{ padding: "26px", borderRadius: "24px" }}
                >
                  <Typography sx={{ fontWeight: "700", color: "#362B24" }}>
                    <div style={{ fontSize: "16px",fontFamily:'Heebo' }}>Order Products</div>
                  </Typography>

                  <Table
                    sx={{ minWidth: 650, marginTop: 2 }}
                    aria-label="custom table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#362B24",
                            fontWeight: "500",
                            fontFamily: "Heebo",
                            fontSize: "16px",
                          }}
                        >
                          Image
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#362B24",
                            fontWeight: "500",
                            fontFamily: "Heebo",
                            fontSize: "16px",
                          }}
                        >
                          Product Name
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#362B24",
                            fontWeight: "500",
                            fontFamily: "Heebo",
                            fontSize: "16px",
                          }}
                        >
                          Category
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#362B24",
                            fontWeight: "500",
                            fontFamily: "Heebo",
                            fontSize: "16px",
                          }}
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#362B24",
                            fontWeight: "500",
                            fontFamily: "Heebo",
                            fontSize: "16px",
                          }}
                        >
                          Regular Price
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#362B24",
                            fontWeight: "500",
                            fontFamily: "Heebo",
                            fontSize: "16px",
                          }}
                        >
                          Item Total
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#362B24",
                            fontWeight: "500",
                            fontFamily: "Heebo",
                            fontSize: "16px",
                          }}
                        >
                          Discounted Price
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowss.map((row) => (
                        <TableRow
                          key={row.userName}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            <img
                              src={row.userImage}
                              alt="img"
                              style={{
                                height: "35px",
                                width: "35px",
                                marginRight: "8px",
                                borderRadius: "10px",
                              }}
                            />
                          </TableCell>
                          <TableCell>{row.productName}</TableCell>
                          <TableCell>{row.catgeory}</TableCell>
                          <TableCell>{row.quantity}</TableCell>
                          <TableCell>{row.regular}</TableCell>
                          <TableCell>{row.itemtotal}</TableCell>
                          <TableCell>{row.discount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {/* <Paginations/> */}
                </TableContainer>
              
              </Box>
            </Box>
          )}
         
        </Box>
      </Modal>
    
    </>
      )}
    </div>
  );
};

export default AllOrder;
