import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Typography,
  TableHead,
} from "@mui/material";
import Paginations from "../../common/pagination/Paginations";
import { handleContactedPersonApi } from '../../api';
import { useSelector } from 'react-redux';
import Loader from '../../common/loader/Loader';

const UserContactedList = () => {
  const token = useSelector((state) => state?.user?.user?.token);

  const [contactedData, setContactedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages,setTotalPages]=useState()
  const [currentPage,setCurrentPage]=useState(1)


  // console.log("ss",currentPage,totalPages);
  
  const headers = ["name", "email", "phone", "message", "Date"];

  const allContactedList = async () => {
    try {
      setLoading(true);
      const response = await handleContactedPersonApi({token,currentPage});
      setContactedData(response?.contacts || []);
      setCurrentPage(response?.currentPage)
      setTotalPages(response?.totalPages||1)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    allContactedList();
  }, [currentPage]);
  
  useEffect(() => {
    allContactedList();
  }, []);

  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };
  
  

  return (
    <div>
      {loading ? (
       
          <Loader />
      
      ) : (
        <Box sx={{ marginTop: "40px", marginBottom: "30px" }}>
          <TableContainer component={Paper} sx={{ padding: "26px", borderRadius: "24px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="custom table">
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell key={header} className="allvender">
                      {header.charAt(0).toUpperCase() + header.slice(1)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {contactedData?.length > 0 ? (
                  contactedData?.map((row, index) => (
                    <TableRow
                      key={row._id || index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {/* <TableCell>{index + 1}</TableCell> */}
                      <TableCell>{row?.name || "-"}</TableCell>
                      <TableCell>{row?.email || "-"}</TableCell>
                      <TableCell>{row?.phone || "-"}</TableCell>
                      <TableCell>{row?.message || "-"}</TableCell>
                      <TableCell>
                        {new Date(row?.createdAt || "-").toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={headers.length}>
                      <Typography align="center">No Data Found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Paginations
             totalPages={totalPages}
             currentPage={currentPage}
             onPageChange={handlePageChange}
             disabled={loading}
            />
          </TableContainer>
        </Box>
      )}
    </div>
  );
};

export default UserContactedList;
