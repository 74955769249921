import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  IconButton,
  Button,
  TableHead,
  Modal,
} from "@mui/material";
import EyeIcon from "../../common/assests/eyegreen.png";
import LockIcon from "../../common/assests/lockicons.png";
import DeletIcon from "../../common/assests/deleticon.png";
import Adminimg from "../../common/assests/adminimg.png";
import './Vender.css'

import Paginations from "../../common/pagination/Paginations";
import { useNavigate } from 'react-router-dom';
import { allVenderListApi } from '../../api';
import { useSelector } from 'react-redux';
import Loader from '../../common/loader/Loader';


const AllVendor = () => {
  const token = useSelector((state) => state?.user?.user?.token);

  const isSmallScreen = useMediaQuery("(max-width:480px)")
  const [rows, setRows] = useState([
    {
      id: 1,
      userName: "Vender Name",
      userImage: Adminimg,
      businessName: "Business A",
      businessImage: Adminimg,
      Email: "abc@gmail.com",
      mono: "(+41) 789 441 6542",
      street: "123 Street",
      address:"US",
      city: "abc",
      state: "xyz",
      zipcode: "12345",
      country: "America",
      totalPrice: "$100",
      quantity: 5,
      paymentStatus: "Paid",
      status: "Active",
      CreatedDate: "2024-08-09",
      bankdetail:"filled"
    },
    {
      id: 2,
      userName: "Vender Namess",
      userImage: Adminimg,
      businessName: "Business A",
      businessImage: Adminimg,
      Email: "abc@gmail.com",
      mono: "(+41) 789 441 6542",
      street: "123 Street",
      address:"London",
      city: "abcd",
      state: "xyzw",
      zipcode: "1266665",
      country: "Canada",
      totalPrice: "$100",
      quantity: 5,
      paymentStatus: "Paid",
      status: "Active",
      CreatedDate: "2024-08-09",
      bankdetail:"filled"
    },
  ]);

  const [venderData,setVenderData]=useState([]);
  const [loading,setLoading]=useState(false);

  const [open, setOpen] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const navigate=useNavigate();

  const handleClickOpen = (row) => {
    navigate('/vender-management/vender-details', {
      state: { selectedRow: row } 
  });
    setSelectedRow(row);
    setOpen(true);
  };


  const headers=["Business Name","Email","Mobile no","Bank Details","Status","Address","Created Date","Action"]

  console.log("allvendir",venderData);
  
  const handleAllVenderList = async () => {
    try {
      let type=2
      setLoading(true);
      const response = await allVenderListApi({token,type});
      setVenderData(response?.users|| []);
      // console.log("usr",response);
      // setLimit(response?.limit)
      // setCurrentPage(response?.page)
      // setTotalPages(response?.totalPages)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  useEffect(()=>{
    handleAllVenderList()
  },[])



  return (
    <div>

{loading ? (
  <Loader/>
):(
<Box sx={{ marginTop: "40px", marginBottom: "30px" }}>
       <TableContainer
          component={Paper}
          sx={{ padding: "26px", borderRadius: "24px" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="custom table">
          <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell key={index} className="allvender">
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

            <TableBody>
              {venderData.length >0 ?(              
                venderData.map((row) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <img
                          src={row.userImage}
                          alt={row.userName}
                          style={{
                            height: "45px",
                            width: "45px",
                            marginRight: "8px",
                            borderRadius: "50%",
                          }}
                        /> */}
                        <Typography>{row?.fullname||""}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{row?.email||""}</TableCell>
                    <TableCell>{row?.phone||""}</TableCell>
                    <TableCell>{row?.bankdetail||""}</TableCell>
                    <TableCell>{row.status}</TableCell>
                   <TableCell>
              {[
                  row?.address?.address ||"", 
                  row?.address?.city||"",
                  row?.address?.state||"",
                  row?.address?.country||"",
                  row?.address?.zipcode||""
                ]
                  .filter(Boolean) 
                  .join(", ")}   
              </TableCell>
                    <TableCell>{row?.CreatedDate}</TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        justifyContent:'flex-start',
                        alignItems: "center",
                        minHeight: "110px"
                       
                      }}
                    >
                      <IconButton onClick={() => handleClickOpen(row)}>
                        <img
                          src={EyeIcon}
                          alt="viewicon"
                          style={{
                            height: isSmallScreen ? "35px" : "42px",
                            width: isSmallScreen ? "35px" : "45px",
                          }}
                        />
                      </IconButton>
                      <IconButton >
                        <img
                          src={LockIcon}
                          alt="viewicon"
                          style={{
                            height: isSmallScreen ? "35px" : "42px",
                            width: isSmallScreen ? "35px" : "45px",
                          }}
                        />
                      </IconButton>
                      <IconButton >
                        <img
                          src={DeletIcon}
                          alt="viewicon"
                          style={{
                            height: isSmallScreen ? "35px" : "42px",
                            width: isSmallScreen ? "35px" : "45px",
                          }}
                        />
                      </IconButton>
                     
                    </TableCell>
                  </TableRow>
                ))
              ):(
                <TableRow align='center'>
                <TableCell colSpan={headers.length}>
                  <Typography align="center">No Data Found</Typography>
                </TableCell>
              </TableRow>
              )}
              
            </TableBody>
           
          </Table>

          <Paginations/>

        </TableContainer>
      </Box>
)}
    </div>
  )
}

export default AllVendor