import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import backImg from "../../common/assests/backImg.png";
import UserImg from '../../common/assests/sampleuser.svg'
import TestingImg from "../../common/assests/gridimg3.png";
import { useLocation, useNavigate } from 'react-router-dom';
import './UserInformation.css'
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import CallIcon from '@mui/icons-material/Call';
import AllOrder  from './AllOrder';
import ResourceAndArticle from './ResourceAndArticle';
import JoinedForum from './JoinedForum';
import AllPost from './AllPost';
import { updateUserApi } from '../../api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';



const UserInformation = () => {
    
  const token = useSelector((state) => state?.user?.user?.token);
    
  const navigate=useNavigate();
    const location = useLocation();
    const { data } = location.state || {};
    // console.log("Ss",data);
    // console.log("aa",location);
    const accountStatus = data?.account_status;
    const genderValue = data?.gender; 
    const [activeComponent, setActiveComponent] = useState(0);

   
    const [accStatus,setAccStatus]=useState(data?.account_status)
    
    // useEffect(()=>{
      
    // },[change,data])

    const handleDeletUser=async(row)=>{
      const isConfirmed = window.confirm("Are you sure, you want to delete this user?");
      if (!isConfirmed) {
        return;
      }
        try {
          const response = await updateUserApi({token,id:row._id,account_status:4})
          setAccStatus(response?.data?.account_status)
          
         navigate('/user-management')
          toast.success(response.message)
        } catch (error) {
          toast.error(error.message)
          console.log("ee",error);
        }
    }
  
    
  
    const handleBlockUser=async(row)=>{
      const isConfirmed = window.confirm("Are you sure, you want to Block this user?");
      if (!isConfirmed) {
        return;
      }
        try {
          const response = await updateUserApi({token,id:row._id,account_status:3})
          setAccStatus(response?.data?.account_status)
          // console.log("sreee",response);
          
       
          toast.success(response.message)
        } catch (error) {
          toast.error(error.message)
          console.log("ee",error);
        }
    }
  
    const handleResumeUser=async(row)=>{
      const isConfirmed = window.confirm("Are you sure, you want to Resume this user?");
      if (!isConfirmed) {
        return;
      }
        try {
          const response = await updateUserApi({token,id:row._id,account_status:2})
          setAccStatus(response?.data?.account_status)
       
          toast.success(response.message)
         
        } catch (error) {
          toast.error(error.message)
          console.log("ee",error);
        }
    }
  
    
    
    const getStatusLabel = (status) => {
      switch (status) {
        case 1:
          return 'Unverified';
        case 2:
          return 'Active';
        case 3:
          return 'Blocked';
        case 4:
          return 'Deleted';
        default:
          return '-';
      }
    };

    const getGenderLabel = (value) => {
      switch (value) {
        case 1:
          return 'Male';
        case 2:
          return 'Female';
        default:
          return '-';
      }
    };
    


  const handleToggle = (index) => {
    setActiveComponent(index);
  };

    const handleBack=()=>{
        navigate(-1)
    }

  return (
    <div style={{maxWidth:'1420px'}}>
      <Box sx={{ marginTop: '15px', paddingLeft: '10px'}}>
      <Typography
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          gap: '15px',
          width: "20%",
          alignItems: "center",
          cursor: "pointer",
        }}
      
      >
    <img
      src={backImg}
      alt="backImg"
      style={{ height: "29px", width: "29px" }}
      onClick={handleBack}
    />
    <Typography
      sx={{ color: "#065F46", fontWeight: "500", fontSize: "20px" }}
      onClick={handleBack}
    >
      Back
    </Typography>
  </Typography>
</Box>

<Box
  sx={{
    borderRadius: '24px',
    backgroundColor: '#FFF',
    boxShadow: '3px 2px 20px 0px rgba(0, 0, 0, 0.04)',
    maxWidth: '1460px',
    width:'100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column', 
    padding: '16px',
    boxSizing: 'border-box',
    p:4,
    mt:'30px'
  }}
>
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center', 
      mb: '20px',
      flexWrap:'wrap',
      // gap:'10px'
      
    }}
  >
        <Typography 
      fontFamily="Heebo" 
      fontWeight={700} 
      color="#362B24"
      fontSize={20}
    >
      User Information
    </Typography>

<Box display="flex" flexWrap='wrap'gap={1} sx={{mt:{xs:'10px',sm:'0px',md:'0px'}}} >
  <Button
    sx={{
      borderRadius: '15px',
      backgroundColor: '#065F46',
      width: { xs: '70px',sm:'100px', md: '124px' }, 
      height: { xs: '40px', md: '50px' },   
      flexShrink: 0,
      fontSize:{xs:'10px',sm:'14px'},
      color: '#FFF',
      marginLeft: '8px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#065F46',
      },
    }}
  >
   Edit User
  </Button>
  <Button
    onClick={()=>handleDeletUser(data)}
    sx={{
      borderRadius: '15px',
      backgroundColor: '#EA001B',
      width: { xs: '70px',sm:'100px', md: '124px' },
      height: { xs: '40px', md: '50px' },   
      flexShrink: 0,
      color: '#FFF',
      marginLeft: '8px',
      textTransform: 'none',
      fontSize:{xs:'10px',sm:'14px'},
      '&:hover': {
        backgroundColor: '#EA001B',
      },
    }}
  >
    Delete
  </Button>
  <Button
        onClick={() => (accStatus === 3 ? handleResumeUser(data) : handleBlockUser(data))}
        sx={{
          borderRadius: '15px',
          backgroundColor: '#362B24',
          width: { xs: '70px', sm: '100px', md: '124px' },
          height: { xs: '40px', md: '50px' },
          color: '#FFF',
          marginLeft: '8px',
          textTransform: 'none',
          fontSize: { xs: '10px', sm: '14px' },
          '&:hover': {
            backgroundColor: '#362B24',
          },
        }}
      >
        {accStatus === 3 ? 'Resume' : 'Block'}
      </Button>
</Box>

  </Box>

  {/* Grid layout  */}
 <Grid container alignItems="flex-start" gap={3} mt={5} mb={2}>
  {/* Single Row: Image and Text Column */}
  <Grid item xs={12} md={4}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: { xs: 'center',sm:'center', md: 'center',lg:'center',xl:'flex-start' },
        // mt: { xs: '10px',sm:'10px' },
        textAlign: { xs: 'center', md: 'left' },
        flexWrap:'wrap'
      }}
    >
     
      <Box
        component="img"
        src={data?.picture 
          ? `https://d33d0argf0d4xk.cloudfront.net/${data.picture}` 
          : UserImg }  
        alt="user img"
        sx={{
          objectFit: 'cover',
          borderRadius: '50%',
          // height: '124px',
          // width: '124px',
          height:'100px',
          width:'100px',
          mr: { lg:3,xl: 3, xs: 0 },
          mb: { xs: 2, md: 0 },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap:'wrap',
          marginTop:{md:'25px',xl:'0px'},
          alignItems: { xs: 'center',sm:'center', md: 'center', lg: 'center',xl:'flex-start' },
          gap: '5px',
        }}
      >
        <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#362B24',textWrap:'wrap' }}>
         {data?.fullname||"-"}
        </Typography>
        <Typography sx={{ color: '#065F46', display: 'flex', alignItems: 'center', mt: 1 }}>
          <EmailRoundedIcon sx={{ mr: 1 }} />
        {data?.email||"-"}
        </Typography>
        <Typography sx={{ color: '#065F46', display: 'flex', alignItems: 'center', mt: 1 }}>
          <CallIcon sx={{ mr: 1 }} />
          {data?.phone||"-"}
        </Typography>
      </Box>
    </Box>
  </Grid>

  {/* Second Column */}
  <Grid item xs={12} md={6} >
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      {/* First Grid Row */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)', md: 'repeat(3, 1fr)',lg: 'repeat(3, 1fr)',xl:'repeat(4, 1fr)' },
          gap: '16px',
          mt:{sm:'20px',md:'0px'}
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Status</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>{getStatusLabel(accountStatus)}</strong>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Date of Birth</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
          {data?.dob ? new Date(data.dob).toLocaleDateString("en-US") : "-"}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Gender</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>{getGenderLabel(genderValue)}</strong>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Address</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong> {data ? data.address?.address || "-" : "-"}</strong>
          </Typography>
        </Box>
      
      </Box>

      {/* {[
                  row?.address?.address ||"", 
                  row?.address?.city||"",
                  row?.address?.state||"",
                  row?.address?.country||"",
                  row?.address?.zipcode||""
                ]
                  .filter(Boolean) 
                  .join(", ")}    */}

      {/* Third Grid Row */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)', md: 'repeat(3, 1fr)',lg: 'repeat(3, 1fr)',xl:'repeat(4, 1fr)' },
          gap: '16px',
          // mt:{md:'10px',lg:'10px'}
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>City</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>{data ? data?.address?.city || "N/A" : "N/A"}</strong>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>State</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>  {data ? data?.address?.state || "N/A" : "N/A"}</strong>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Country</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>  {data ? data?.address?.country || "N/A" : "N/A"}</strong>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Zip Code</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>  {data ? data?.address?.zipcode || "N/A" : "N/A"}</strong>
          </Typography>
        </Box>
      </Box>
    </Box>
  </Grid>
</Grid>


</Box>





      

<div className="user-info-toggle-container d-flex justify-content-between align-items-center flex-wrap mt-4">
  <div className="user-info-button-box">
    <div
      id="user-info-btn"
      style={{ 
        left: `${activeComponent * 25}%`, 
        width: `24%` 
      }}
    ></div>
    <button
      className={`user-info-toggle-btn ${activeComponent === 0 ? 'active' : ''}`}
      onClick={() => handleToggle(0)}
    >
      All Order
    </button>
    <button
      className={`user-info-toggle-btn ${activeComponent === 1 ? 'active' : ''}`}
      onClick={() => handleToggle(1)}
    >
      Resources & Articles
    </button>
    <button
      className={`user-info-toggle-btn ${activeComponent === 2 ? 'active' : ''}`}
      onClick={() => handleToggle(2)}
    >
      Joined Forums
    </button>
    <button
      className={`user-info-toggle-btn ${activeComponent === 3 ? 'active' : ''}`}
      onClick={() => handleToggle(3)}
    >
      All Posts
    </button>
  </div>
</div>



{activeComponent === 0 && <AllOrder />}
{activeComponent === 1 && <ResourceAndArticle  />}
{activeComponent === 2 && <JoinedForum />}
{activeComponent === 3 && <AllPost />}
     


    
   
    </div>
  )
}

export default UserInformation