import React, { useState } from "react";
import { useTheme } from "@mui/material/styles"; 
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  InputAdornment,
  Typography,
} from "@mui/material";
import emailIcon from "../../common/assests/emailicon.png";
import backImg from "../../common/assests/backImg.png";
import { toast } from "react-toastify";
import { handleForgetApi } from "../../api";
import CustomBtn from "../../common/customButton/CustomBtn";

const ForgotPassword = ({ open, onClose, onNext, }) => {
  const theme = useTheme();
  const [email,setEmail]=useState("");
  const [btnDisabale, setBtnDisable] = useState(false);


  const handleBack = () => {
    onClose();
  };


  const handlesendCode=async()=>{
      if(!email){
        toast.warning("Email Required");
        return;
      }
      try {
        setBtnDisable(true)
        const response=await handleForgetApi(email)
        const refId = response.refData;
        toast.success("Email Verified !");
        setEmail("")
        onNext(refId)
      } catch (error) {
        setBtnDisable(false)
          toast.error(error.message);
          console.log("error", error);
        
      }

  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      // maxWidth="xs"
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "32px",
          background: "#FFF",
          width: "100%",
          maxWidth: "480px",
          boxSizing: "border-box",
          marginRight: {
            xs: "30px",
            sm: "30px",
            md: "30px",
            lg: "35px",
            xl: "45px",
          
          },
         '@media (min-width: 1600px)': {
        marginRight: "32px",
      },
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogContent sx={{ padding: "20px" }}>
      <Box sx={{marginTop:'15px',paddingLeft:'10px'}}>
          <Typography
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap:'10px',
              width: "20%",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleBack}
          >
            <img
              src={backImg}
              alt="backImg"
              style={{ height: "29px", width: "29px" }}
            />
            <Typography
              sx={{ color: "#065F46", fontWeight: "500", fontSize: "20px" }}
            >
              Back
            </Typography>
          </Typography>
        </Box>
      </DialogContent>

      <DialogTitle
        sx={{
         
          fontSize: { xs: "20px", sm:'24px' },
          fontWeight: "700",
          fontFamily: "Heebo",
          paddingLeft:'35px'
        }}
      >
        Forgot Password
      </DialogTitle>

      <DialogTitle
        sx={{
          marginTop: "-27px",
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Heebo",
          opacity: "0.5",
          paddingLeft:'35px'
        }}
      >
        Enter your mail we will send you a Code
      </DialogTitle>

      <DialogContent sx={{ width: "95%", margin: "auto", marginTop: "25px" }}>
        <Typography
          sx={{
            marginBottom: "8px",
            textAlign: "left",
            fontSize: { xs: "14px", sm: "17px" },
            fontWeight: "600",
            fontFamily: "Heebo",
            textIndent: "18px",
          }}
        >
          Email
        </Typography>
        <TextField
          fullWidth
          type="email"
          placeholder="Type your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            borderRadius: "28px",
            // opacity: "0.3",
            // border: "1px solid #362B24",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src={emailIcon}
                  alt="email-logo"
                  className="img-fluid"
                  style={{ width: "20px" }}
                />
              </InputAdornment>
            ),
            style: { borderRadius: "28px" },
          }}
        />
      </DialogContent>

      <DialogActions sx={{ width: "90%", margin: "auto", marginBottom: "40px",display:'flex',justifyContent:'center' }}>
        {/* <Button
          onClick={handlesendCode}
          sx={{
            width: "100%",
            maxWidth: "410px",
            height: "60px",
            flexShrink: 0,
            borderRadius: "30px",
            background: "linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)",
            boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Heebo",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "13px",
            textTransform: "none",
          }}
        >
          Send Code
        </Button> */}
        <CustomBtn onClick={handlesendCode} text="Send Code" disable={btnDisabale}/>
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPassword;
