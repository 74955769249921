
import React, { useEffect, useState } from 'react'
import './UserManagement.css';
import AllUserList from './AllUserList';
import UserContactedList from './UserContactedList';


const UserManagement = () => {
  
  const [activeComponent, setActiveComponent] = useState(0);

  const handleToggle = (index) => {
    setActiveComponent(index);
  };

  return (
    
    <div>
      
    <div className="custom-total-sales-container d-flex justify-content-between align-items-center flex-wrap ">
     <div className="custom-button-box">
       <div
         id="custom-btn"
         style={{ 
           left: `${activeComponent *50}%`, 
           width: `47%` 
         }}
       ></div>
       <button
         className={`custom-toggle-btn ${activeComponent === 0 ? 'active' : ''}`}
         onClick={() => handleToggle(0)}
       >
        All User List
       </button>
       <button
         className={`custom-toggle-btn ${activeComponent === 1 ? 'active' : ''}`}
         onClick={() => handleToggle(1)}
       >
        Contacted User List
       </button>
     </div>
   </div>
   
   
   {activeComponent === 0 && <AllUserList />}
   {activeComponent === 1 && <UserContactedList/>}
       </div>
  )
}

export default UserManagement

