import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import SideBar from "../sidebar/SideBar";
import './Main.css';
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";

const Main = ({ children }) => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [isMdOrAbove, setIsMdOrAbove] = useState(window.innerWidth > 1024);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleResize = () => {
    setIsMdOrAbove(window.innerWidth > 1024);
  };

  const handleActiveLinkChange = (link) => {
    setActiveLink(link);
  };


  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AuthenticatedLayout>
    <div style={{ height: '100vh', background: '#F5F5F5', overflowY: 'auto' }}>
      <SideBar 
        isMdOrAbove={isMdOrAbove} 
        openSidebarToggle={isMdOrAbove ? true : openSidebarToggle} 
        OpenSidebar={OpenSidebar} 
        onActiveLinkChange={handleActiveLinkChange} 
      />
      <Navbar OpenSidebar={OpenSidebar} isMdOrAbove={isMdOrAbove} activeLink={activeLink} />
      <main 
        className={`main-container ${!isMdOrAbove && openSidebarToggle ? 'sidebar-open' : ''}`}
      >
        {children}
      </main>
    </div>
    </AuthenticatedLayout>
  );
};

export default Main;
