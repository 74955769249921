import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  InputAdornment,
  Typography,
} from "@mui/material";
import passwordIcon from "../../common/assests/password.png";
import backImg from "../../common/assests/backImg.png";
import { toast } from "react-toastify";
import CustomBtn from "../../common/customButton/CustomBtn";
import { handleChangePassApi } from "../../api";

const ConfirmPassModal = ({
  open,
  onClose,
  onSubmit,
  onBack,
  refId
}) => {

  const [btnDisabale, setBtnDisable] = useState(false);
 
  const [newPassword,setNewPassword]=useState("");
  const [confirmPassword,setConfirmPassword]=useState("");
  
 

  const handleSubmitNewPassword=async()=>{
    if (!newPassword || !confirmPassword) {
      toast.warning("All fields are required");
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.warning("Passwords did not match");
      return;
    }
    if (newPassword.length < 8) {
      toast.warning("Password should be at least 8 characters long");
      return;
    }
    try {
      setBtnDisable(true)
      const response=await handleChangePassApi({refId,newPassword})
      toast.success(response.message)
      setNewPassword("")
      setConfirmPassword("")
      onSubmit();
      onClose();
      
    } catch (error) {
      setBtnDisable(false)
      toast.error(error.message);
      console.log("error", error);
  } 
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      // maxWidth="xs"
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "32px",
          background: "#FFF",
          width: "100%",
          maxWidth: "480px",
          boxSizing: "border-box",
          marginRight: {
            xs: "30px",
            sm: "30px",
            md: "30px",
            xl: "45px",
          
          },
          '@media (min-width: 1600px)': {
        marginRight: "32px",
      },
        },
      }}
    >
      <DialogContent sx={{ padding: "10px" }}>
        <Box sx={{marginTop:'20px',paddingLeft:'20px'}}>
          <Typography
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "20%",
              alignItems: "center",
              cursor: "pointer",
               gap:'10px'
            }}
            onClick={onBack}
          >
            <img
              src={backImg}
              alt="backImg"
              style={{ height: "29px", width: "29px" }}
            />
            <Typography
              sx={{ color: "#065F46", fontWeight: "500", fontSize: "20px" }}
            >
              Back
            </Typography>
          </Typography>
        </Box>

        <DialogTitle
          sx={{
            textAlign: "left",
            fontSize: "24px",
            fontWeight: "700",
            fontFamily: "Heebo",
            marginTop:'20px',paddingLeft:'30px'
          }}
        >
          Set New Password
        </DialogTitle>
        <DialogTitle
          sx={{
            marginTop: "-27px",
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "Heebo",
            opacity: "0.5",
            paddingLeft:'30px'
          }}
        >
          Set your password here whatever you wanted
        </DialogTitle>

        <Box sx={{ width: "90%", margin: "0 auto" }}>
          <Typography
            sx={{
              marginBottom: "8px",
              marginTop:'20px',
              textAlign: "left",
              marginLeft: "20px",
              fontSize: "17px",
              fontWeight: "600",
              fontFamily: "Heebo",
              color: "#171717",
            }}
          >
            New Password
          </Typography>
          <TextField
            fullWidth
            type="password"
            placeholder="Type your new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            sx={{
              borderRadius: "28px",
              // opacity: "0.3",
              // border: "1px solid #362B24",
              marginBottom: "12px",
              fontSize:'10px'
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={passwordIcon}
                    alt="password-icon"
                    style={{ width: "20px" }}
                  />
                </InputAdornment>
              ),
              style: { borderRadius: "28px" },
            }}
          />
        </Box>
        <Box sx={{ width: "90%", margin: "0 auto" }}>
          <Typography
            sx={{
              marginBottom: "8px",
              textAlign: "left",
              marginLeft: "20px",
              fontSize: "17px",
              fontWeight: "600",
              fontFamily: "Heebo",
              marginTop:'10px'
            }}
          >
            Retype Password
          </Typography>
          <TextField
            fullWidth
            type="password"
            placeholder="Retype your password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={{
              borderRadius: "28px",
              // opacity: "0.3",
              // border: "1px solid #362B24",
              marginBottom: "20px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={passwordIcon}
                    alt="password-icon"
                    style={{ width: "20px" }}
                  />
                </InputAdornment>
              ),
              style: { borderRadius: "28px" },
            }}
          />
        </Box>

        <Box
          sx={{
            width: "90%",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            marginTop:'15px',
            marginBottom:'20px'
          }}
        >
       
          <CustomBtn onClick={handleSubmitNewPassword} text="Submit" disable={btnDisabale}/>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmPassModal;
