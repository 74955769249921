
import { Axios } from "./axios.interceptor";
// import { useState } from "react";
// import { useSelector } from "react-redux";
// import { store } from './redux/store';
// export const token = useSelector((item)=>item.user.user.token) 
//   const token = store?.getState()?.user?.user?.token;

/*****************************************************Signup and Forget*********************************** */
export const handleSignUp = (userData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.post(
                "api/v1/admin/login",
                {
                    email: userData.email,
                    password: userData.password,

                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {

            reject(error.response.data);
        }
    });
};


export const handleForgetApi = (email) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.post(
                "api/v1/admin/forget-password",
                {
                    email
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
            reject(error.response.data);
        }
    });
};


export const handleOtpApi = ({ refId, otp }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.post(
                "api/v1/admin/verify-otp",
                {
                    refId, otp
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
            reject(error.response.data);
        }
    });
};


export const handleResendOtpApi = (refId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.post(
                "api/v1/admin/resend-otp",
                {
                    refId
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
            reject(error.response.data);
        }
    });
};

export const handleChangePassApi = ({refId,newPassword}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.post(
                "api/v1/admin/reset-password",
                {
                    refId,newPassword
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
            reject(error.response.data);
        }
    });
};



/*******************************************************UserManangemnet / Contact Us Landing page data********************/


export const handleContactedPersonApi=({token,currentPage})=>{
    return new Promise (async(resolve,reject)=>{
        try {  
            const response = await Axios.get(
                `api/v1/admin/get-contact-us?page=${currentPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }   
            );
            resolve(response);
        } catch (error) {
            reject(error.response.data);
        }
    })
}


export const allUserListApi=({token,type,currentPage,limit=10})=>{
    return new Promise (async(resolve,reject)=>{
        try {  
            const response = await Axios.get(
                `api/v1/admin/managment/user?type=${type}&page=${currentPage}&limit=${limit}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }   
            );
            resolve(response.data);
        } catch (error) {
            reject(error.response.data);
        }
    })
}

export const allOrderListApi=({token,id,currentPage})=>{
    return new Promise (async(resolve,reject)=>{
        try {  
            const response = await Axios.get(
                `api/v1/admin/managment/order/${id}?page=${currentPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }   
            );
            resolve(response.data);
        } catch (error) {
            reject(error.response.data);
        }
    })
}

export const OrderDetailsApi=({token,id})=>{
    return new Promise (async(resolve,reject)=>{
        try {  
            const response = await Axios.get(
                `api/v1/admin/managment/orderDetail/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }   
            );
            resolve(response.data);
        } catch (error) {
            reject(error.response.data);
        }
    })
}

export const allResourcesListApi=({token,currentPage,id})=>{
    return new Promise (async(resolve,reject)=>{
        try {  
            const response = await Axios.get(
                `api/v1/admin/managment/resources/${id}?page=${currentPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }   
            );
            resolve(response.data);
        } catch (error) {
            reject(error.response.data);
        }
    })
}


export const allForumListApi=({token,id,currentPage})=>{
    return new Promise (async(resolve,reject)=>{
        try {  
            const response = await Axios.get(
                `api/v1/admin/managment/joinedForums/${id}?page=${currentPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }   
            );
            resolve(response.data);
        } catch (error) {
            reject(error.response.data);
        }
    })
}

export const allUserPostListApi=({token,currentPage,id})=>{
    return new Promise (async(resolve,reject)=>{
        try {  
            const response = await Axios.get(
                `api/v1/admin/managment/posts/${id}?page=${currentPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }   
            );
            resolve(response.data);
        } catch (error) {
            reject(error.response.data);
        }
    })
}

// used for delete , block , update this same api
export const updateUserApi=({token,id,account_status})=>{
    return new Promise (async(resolve,reject)=>{
        try {  
            const response = await Axios.put(
                `api/v1/admin/managment/update-user/${id}`,{account_status},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }   
            );
            resolve(response);
        } catch (error) {
            reject(error.response.data);
        }
    })
}






/**************************************************  Vender Management **********************************************/


export const allVenderListApi=({token,type,currentPage,limit})=>{
    return new Promise (async(resolve,reject)=>{
        try {  
            const response = await Axios.get(
                `api/v1/admin/managment/user?type=${type}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }   
            );
            resolve(response.data);
        } catch (error) {
            reject(error.response.data);
        }
    })
}






