import React from 'react';
import { Button } from '@mui/material';

const CustomBtn = ({ onClick, text,disable }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disable}
      sx={{
        width: "100%",
        maxWidth: "410px",
        height: "60px",
        flexShrink: 0,
        borderRadius: "30px",
        background: "linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)",
        boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
        color: "#FFF !important",
        textAlign: "center",
        fontFamily: "Heebo",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "13px",
        textTransform: "none",
      }}
      
    >
      {text}
    </Button>
  );
};

export default CustomBtn;
