
import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  IconButton,
  Button,
  TableHead,
  Modal,
} from "@mui/material";
import EyeIcon from "../../common/assests/eyegreen.png";
import LockIcon from "../../common/assests/lockicons.png";
import DeletIcon from "../../common/assests/deleticon.png";
import BlockIcon from "../../common/assests/blockIcon.png";
import Adminimg from "../../common/assests/adminimg.png";
import UserImg from '../../common/assests/sampleuser.svg'
// import './Vender.css'

import Paginations from "../../common/pagination/Paginations";
import { useNavigate } from 'react-router-dom';
import { allUserListApi, handleContactedPersonApi, updateUserApi } from '../../api';
import { useSelector } from 'react-redux';
import Loader from '../../common/loader/Loader';
import { toast } from 'react-toastify';




const AllUserList = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const navigate=useNavigate();

  const isSmallScreen = useMediaQuery("(max-width:480px)")
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  
  const [loading, setLoading] = useState(false);
  

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const [change,setChange]=useState(false)

  // const [limit,setLimit]=useState(10)

  const [allUserData,setAllUserData]=useState([]);


  // console.log('pages',currentPage,totalPages);
  
  const handleAllUserList = async () => {
    try {
      let type=1
      setLoading(true);
      const response = await allUserListApi({token,currentPage,type});
      setAllUserData(response?.users|| []);
      // setCurrentPage(response?.currentPage)
      console.log('allluserlist',response);
      setTotalPages(response?.totalPages)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }finally {
      setLoading(false)
  }
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };


  useEffect(() => {
    handleAllUserList();
  }, [currentPage,change]);

  
  const handleDeletUser=async(row)=>{
    const isConfirmed = window.confirm("Are you sure, you want to delete this user?");
    if (!isConfirmed) {
      return;
    }
      try {
        const response = await updateUserApi({token,id:row._id,account_status:4})
        setChange(!change);
        toast.success(response.message)
      } catch (error) {
        toast.error(error.message)
        console.log("ee",error);
      }
  }

  

  const handleBlockUser=async(row)=>{
    const isConfirmed = window.confirm("Are you sure, you want to Block this user?");
    if (!isConfirmed) {
      return;
    }
      try {
        const response = await updateUserApi({token,id:row._id,account_status:3})
        setChange(!change);
        toast.success(response.message)
      } catch (error) {
        toast.error(error.message)
        console.log("ee",error);
      }
  }

  const handleResumeUser=async(row)=>{
    const isConfirmed = window.confirm("Are you sure, you want to Resume this user?");
    if (!isConfirmed) {
      return;
    }
      try {
        const response = await updateUserApi({token,id:row._id,account_status:2})
        setChange(!change);
        toast.success(response.message)
      } catch (error) {
        toast.error(error.message)
        console.log("ee",error);
      }
  }

  

  const handleClickOpen = (row) => {
    navigate(`/user-management/user-information/${row._id}`, {
      state: { data: row } 
  });
    setSelectedRow(row);
    setOpen(true);
  };


const  headers=["Name", "Bio", "Email", "Mobile no", "Address", "Action"]


  return (
    <div>

{loading ? (
  <Loader />
) : (
  <Box sx={{ marginTop: "40px", marginBottom: "30px" }}>
    <TableContainer component={Paper} sx={{ padding: "26px", borderRadius: "24px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="custom table">
        <TableHead>
          <TableRow>
          {headers.map((header) => (
              <TableCell key={header} className="allvender">
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {allUserData?.length > 0 ? (
          allUserData?.map((row) => (
           
            <TableRow key={row._id}  sx={{ 
              "&:last-child td, &:last-child th": { border: 0 }, 
              opacity: row?.account_status ===3 ? 0.6 : 1 
            }}>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={row?.picture 
                      ? `https://d33d0argf0d4xk.cloudfront.net/${row.picture}` 
                      : UserImg}  
                    alt={row?.fullname || 'User'}
                    style={{
                      height: "45px",
                      width: "45px",
                      marginRight: "8px",
                      borderRadius: "50%",
                    }}
                  />
                  <Typography>{row?.fullname || "-"}</Typography>
                </Box>
              </TableCell>
              <TableCell>{row?.bio || "-"}</TableCell>
              <TableCell>{row?.email || "-"}</TableCell>
              <TableCell>{row?.phone || "-"}</TableCell>
              <TableCell>
              {
                row?.address 
                  ? [
                      row.address.address || "",
                      row.address.city || "",
                      row.address.state || "",
                      row.address.country || "",
                      row.address.zipcode || ""
                    ]
                    .filter(Boolean) 
                    .join(", ")
                  : "-"
              }
              </TableCell>
              <TableCell sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <IconButton onClick={() => handleClickOpen(row)}>
                  <img
                    src={EyeIcon}
                    alt="viewicon"
                    style={{
                      height: isSmallScreen ? "35px" : "42px",
                      width: isSmallScreen ? "35px" : "45px",
                    }}
                  />
                </IconButton>
                <IconButton onClick={() => row?.account_status === 3 ? handleResumeUser(row) : handleBlockUser(row)}>
                    {row?.account_status === 3 ? (
                      <img
                        src={BlockIcon} 
                        alt="unlockicon"
                        style={{
                          height: isSmallScreen ? "35px" : "42px",
                          width: isSmallScreen ? "35px" : "45px",
                        }}
                      />
                    ) : (
                      <img
                        src={LockIcon} 
                        alt="lockicon"
                        style={{
                          height: isSmallScreen ? "35px" : "42px",
                          width: isSmallScreen ? "35px" : "45px",
                        }}
                      />
                    )}
                  </IconButton>
                <IconButton onClick={()=>handleDeletUser(row)}>
                  <img
                    src={DeletIcon}
                    alt="deleteicon"
                    style={{
                      height: isSmallScreen ? "35px" : "42px",
                      width: isSmallScreen ? "35px" : "45px",
                    }}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))
        ):(
          <TableRow align='center'>
          <TableCell colSpan={headers.length}>
            <Typography align="center">No Data Found</Typography>
          </TableCell>
        </TableRow>
        )}
        </TableBody>
      </Table>

      <Paginations
       totalPages={totalPages}
       currentPage={currentPage}
       onPageChange={handlePageChange}
       disabled={loading}
      />
    </TableContainer>
  </Box>
)}

    </div>
  )
}

export default AllUserList