import React, { useState } from 'react';
import { Box, Typography, TextField, Button, InputAdornment } from '@mui/material';
import CloseEyeIcon from '../../common/assests/eclose.svg';
import OpenEyeIcon from '../../common/assests/eopen.svg';

const ChangePassword = ({ onClose }) => {
  const [showPasswords, setShowPasswords] = useState({
    oldPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const handleClickShowPassword = (field) => {
    setShowPasswords((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const onSave = () => {
    onClose();
  };

  return (
    <div>
      {/* Old Password */}
      <Typography
        sx={{
          marginBottom: '8px',
          textAlign: 'left',
          fontSize: { xs: '14px', sm: '17px' },
          fontWeight: '600',
          fontFamily: 'Heebo',
          textIndent: '18px',
          marginTop: '15px',
        }}
      >
        Old Password
      </Typography>
      <TextField
        fullWidth
        name="oldPassword"
        type={showPasswords.oldPassword ? 'text' : 'password'}
        placeholder="Type your Old password"
        sx={{
          borderRadius: '28px',
          opacity: '0.4',
          border: '1px solid #362B24',
          '& .MuiOutlinedInput-root': {
            borderRadius: '25px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={showPasswords.oldPassword ? OpenEyeIcon : CloseEyeIcon}
                alt="toggle-password-visibility"
                onClick={() => handleClickShowPassword('oldPassword')}
                style={{ width: '20px', cursor: 'pointer', opacity: '0.4' }}
              />
            </InputAdornment>
          ),
        }}
      />

      {/* New Password */}
      <Typography
        sx={{
          marginBottom: '8px',
          textAlign: 'left',
          fontSize: { xs: '14px', sm: '17px' },
          fontWeight: '600',
          fontFamily: 'Heebo',
          textIndent: '18px',
          marginTop: '15px',
        }}
      >
        New Password
      </Typography>
      <TextField
        fullWidth
        name="newPassword"
        type={showPasswords.newPassword ? 'text' : 'password'}
        placeholder="Type your New password"
        sx={{
          borderRadius: '28px',
          opacity: '0.4',
          border: '1px solid #362B24',
          '& .MuiOutlinedInput-root': {
            borderRadius: '25px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={showPasswords.newPassword ? OpenEyeIcon : CloseEyeIcon}
                alt="toggle-password-visibility"
                onClick={() => handleClickShowPassword('newPassword')}
                style={{ width: '20px', cursor: 'pointer', opacity: '0.4' }}
              />
            </InputAdornment>
          ),
        }}
      />

      {/* Retype New Password */}
      <Typography
        sx={{
          marginBottom: '8px',
          textAlign: 'left',
          fontSize: { xs: '14px', sm: '17px' },
          fontWeight: '600',
          fontFamily: 'Heebo',
          textIndent: '18px',
          marginTop: '15px',
        }}
      >
        Retype New Password
      </Typography>
      <TextField
        fullWidth
        name="confirmNewPassword"
        type={showPasswords.confirmNewPassword ? 'text' : 'password'}
        placeholder="Retype your new password"
        sx={{
          borderRadius: '28px',
          opacity: '0.4',
          border: '1px solid #362B24',
          '& .MuiOutlinedInput-root': {
            borderRadius: '25px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={showPasswords.confirmNewPassword ? OpenEyeIcon : CloseEyeIcon}
                alt="toggle-password-visibility"
                onClick={() => handleClickShowPassword('confirmNewPassword')}
                style={{ width: '20px', cursor: 'pointer', opacity: '0.4' }}
              />
            </InputAdornment>
          ),
        }}
      />

      {/* Save Button */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button
          onClick={onSave}
          sx={{
            mt: 2,
            width: '337px',
            height: '57px',
            borderRadius: '28.5px',
            background: 'linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)',
            color: 'white',
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: '600',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Save
        </Button>
      </Box>
    </div>
  );
};

export default ChangePassword;
