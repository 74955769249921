import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Modal,
  AvatarGroup,
  Avatar,
  Button,
} from "@mui/material";
import Eyegreen from "../../common/assests/eyegreen.png";
import LockIconImg from "../../common/assests/lockicons.png";
import DeleteIconImg from "../../common/assests/deleticon.png";
import TestingImg from "../../common/assests/gridimg3.png";
import ClockIcon from "../../common/assests/clock.svg";
import CrossIcon from "../../common/assests/croosicon.png";
import Adminimg from "../../common/assests/adminimg.png";
import location from "../../common/assests/location.svg";
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import DeleteIcon from '@mui/icons-material/Delete'; 
import Paginations from "../../common/pagination/Paginations";
import PeopleIcon from '@mui/icons-material/People';
import { useSelector } from "react-redux";
import { allForumListApi, allResourcesListApi } from "../../api";
import { useParams } from "react-router-dom";
import Loader from "../../common/loader/Loader";

const JoinedForum = () => {


  const { id } = useParams();
  const token = useSelector((state) => state?.user?.user?.token);
  const [loading,setLoading]=useState(false)
  const [forumDataList,setForumeDataList]=useState([]);

  const [totalPages,setTotalPages]=useState()
  const [currentPage,setCurrentPage]=useState()

  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([
    {
      id: 1,
      eventImg: TestingImg,
      content: "Event Title",
      cTag: "Event Category",
      clockImg: ClockIcon,
      date: "151 Members",
      txt: "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    },
    {
      id: 2,
      eventImg: TestingImg,
      content: "Event Title",
      cTag: "Category 2",
      clockImg: ClockIcon,
      date: "151 Members",
      txt: "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    },
  ]);





  const handleOpen = (event) => {
    setSelectedEvent(event);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleDelete = (id) => {
    setEvents((prevEvents) => prevEvents.filter((event) => event.id !== id));
  };



   const handleForumList = async () => {
    try { 
      setLoading(true);
      const response = await allForumListApi({token,currentPage,id:id});
      setForumeDataList(response|| []);
      console.log("forums details",response);
      // setLimit(response?.limit)
      setCurrentPage(response?.page)
      setTotalPages(response?.totalPages||"")
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleForumList();
  }, [currentPage]);


  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };

  

  return (
    <div>

      {loading ? (
        <Loader/>
      ):(

      <Box
        sx={{
          borderRadius: "24px",
          background: "#FFF",
          boxShadow: "3px 2px 20px 0px rgba(0, 0, 0, 0.04)",
          padding: "16px",
          width: "100%",
          maxWidth: "1460px",
          margin: "auto",
          boxSizing: "border-box",
          overflow: "hidden",
          marginTop:'40px'
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: "#362B24",
            fontFamily: "Heebo",
          }}
        >
          All Platform Events
        </Typography>

        <Box>
            {forumDataList.length>0 ?(
              forumDataList.map((item) => (
                <Box
                  key={item._id}
                  sx={{
                    borderRadius: "22px",
                    background: "#FFF",
                    boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
                    maxWidth: "1380px",
                    height: "auto",
                    minHeight: "116px",
                    flexShrink: 0,
                    marginBottom: "16px",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "16px",
                    boxSizing: "border-box",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  {/* Left Side: Image and Event Details */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: { xs: "column", sm: "row" },
                      gap: { xs: "20px", sm: "0px" },
                      width: "100%",
                    }}
                  >
                    <img
                      src={item?.picture}
                      alt="event-img"
                      style={{
                        borderRadius: "13px",
                        marginRight: "16px",
                        width: "91px",
                        height: "96px",
                        objectFit: "cover",
                      }}
                    />
                    <Box>
                      <Box sx={{ display: "flex", alignItems: "center", mb: 1,gap:1 }}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#362B24",
                            fontFamily: "Heebo",
                          }}
                        >
                          {item?.title||""}
                        </Typography>
                        <Box
                  sx={{
                    borderRadius: '28px',
                    background: '#362B24',
                    boxShadow: '1px 2px 30px 0px rgba(0, 0, 0, 0.06)',
                    width: '97px',
                    height: '25px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent:'center',
                   gap:'3px',
                    flexShrink: 0,
                   
                    
                  }}
                >
                 <PeopleIcon sx={{color:'#FFF',width:'11px',height:'11px'}}/>
                  <Typography sx={{ color: '#FFF', fontSize: '9px' }}>
                  {item?.members.length} Members
                  </Typography>
                </Box>
    
                      </Box>
  
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#7D6F6F",
                          fontFamily: "Heebo",
                          // overflow: 'hidden',
                          // textOverflow: 'ellipsis',
                          // whiteSpace: 'nowrap',
                          maxWidth: "900px",
                          width: "100%",
                          opacity: "0.6",
                        }}
                      >
                        {item?.description||""}
                      </Typography>
                    </Box>
                  </Box>
    
               
                
                </Box>
              ))
            ):(
                <div style={{textAlign:'center'}}>
                 Data Not Avilable
                </div>
            )}
        </Box>
        <Paginations
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        disabled={loading}
      />
      </Box>
      )}

   
    </div>
  );
};

export default JoinedForum;
