import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material";
import { style, styled, width } from "@mui/system";
import companylogo from "../../common/assests/companylogo.png";
import emailIcon from "../../common/assests/emailicon.png";
import passwordIcon from "../../common/assests/password.png";
import "./SignUp.css";
import ForgotPassword from "../forgot/ForgotPassword";
import OtpModal from "../forgot/OtpModal";
import ConfirmPassModal from "../forgot/ConfirmPassModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleSignUp, hanldeSignUp } from "../../api";
import { useDispatch } from "react-redux";
import { login } from "../../redux/userSlice";
import CustomBtn from "../../common/customButton/CustomBtn";
import Loader from "../../common/loader/Loader";
import CloseEyeIcon from '../../common/assests/eclose.svg';
import OpenEyeIcon from '../../common/assests/eopen.svg';
// import { type } from "@testing-library/user-event/dist/type";
// import { VisibilityOff, Visibility } from "@mui/icons-material";
// import src from "@emotion/styled";


const SignUp = () => {
  const navigate = useNavigate();
  const dispatch=useDispatch();
  
  const [loading,setLoading]=useState(false);
  
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [btnDisabale, setBtnDisable] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);
  const [openNewPassword, setOpenNewPassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [refId,setRefId]=useState();



  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleForgotPasswordClickOpen = () => setOpenForgotPassword(true);
  const handleForgotPasswordClose = () => setOpenForgotPassword(false);

  const handleOtpClickOpen = (refId) => {
    setRefId(refId);
    // setOpenForgotPassword(false);
    setOpenOtp(true);
  };
  const handleOtpClose = () => setOpenOtp(false);

  const handleNewPasswordClickOpen = () => {
    
    setOpenOtp(false);
    setOpenNewPassword(true);
  };
  const handleNewPasswordClose = () =>{
     setOpenNewPassword(false);
  }


  const handleSubmitNewPassword = () => {
    setOpenNewPassword(false);
  };

  const handleBackToForgotPassword = () => {
    setOpenOtp(false);
    setOpenForgotPassword(true);
  };
  const handleBackToOtp = () => {
    setOpenNewPassword(false);
    setOpenOtp(true);
  };



 

  const handleSignIn = async() => {

    if (!credentials.email || !credentials.password) {
      toast.warning("Email and Password required");
      return;
    }
    try {
      setLoading(true)
      setBtnDisable(true)
      const loginuser = await handleSignUp(credentials);
      navigate('/dashboard');
      toast.success(loginuser.message);
      setLoading(false)
      dispatch(login({...loginuser.data,token:loginuser.token}));
      
    } 
    catch (err) {
      setLoading(false)
      setBtnDisable(false);
      toast.error(err.message);
      console.log("error", err);
    } 
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  
  return (
    <div className="outer">

{loading && <Loader/>} 
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          padding: "16px",
        }}
      >
        <StyledCard>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "30px",
              }}
            >
              <img
                src={companylogo}
                alt="logo"
                className="img-fluid"
                style={{ height: "160px", width: "143px" }}
              />
            </Box>

            <Box
              sx={{
                paddingRight: {
                  xs: "3px",
                  sm: "16px",
                  md: "24px",
                  lg: "34px",
                  xl: "34px",
                },
                textAlign: "left",
              }}
            >
              <Typography
                sx={{
                  color: "#362B24",
                  fontWeight: "700",
                  marginTop: "45px",
                  fontFamily: "Heebo",
                  fontSize: "24px",
                  lineHeight: "24px",
                }}
              >
                Hello, Welcome Back
              </Typography>

              <Typography
                sx={{
                  color: "#362B24",
                  fontWeight: "400",
                  marginTop: "15px",
                  fontFamily: "Heebo",
                  fontSize: "16px",
                  lineHeight: "22px",
                  opacity: "0.5",
                }}
              >
                Lorem ipsom is simple dummy text that can be
              </Typography>
            </Box>

            <Box sx={{ width: "90%", marginTop: "40px" }}>
              <Typography
                sx={{
                  marginBottom: "8px",
                  textAlign: "left",
                  marginLeft: "20px",
                  fontSize: "17px",
                  fontWeight: "600",
                  fontFamily: "Heebo",
                }}
              >
                Email
              </Typography>
              <TextField
                fullWidth
                type="email"
                placeholder="Type your email"
                name="email"
                value={credentials.email}
                onChange={handleInputChange}
                sx={{
                  color:'black !important',
                  borderRadius: "28px",
                 
                  // border: "1px solid #362B24",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={emailIcon}
                        alt="email-logo"
                        className="img-fluid"
                        style={{ width: "20px" }}
                      />
                    </InputAdornment>
                  ),
                  style: { borderRadius: "28px" },
                }}
              />
            </Box>

            <Box sx={{ width: "90%", margin: "12px" }}>
              <Typography
                sx={{
                  marginBottom: "8px",
                  textAlign: "left",
                  marginLeft: "20px",
                  fontSize: "17px",
                  fontWeight: "600",
                  fontFamily: "Heebo",
                }}
              >
                Password
              </Typography>
              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                placeholder="Type your Password"
                name="password"
                value={credentials.password}
                onChange={handleInputChange}
                sx={{
                  borderRadius: "28px",
                  // opacity: "0.3",
                  // border: "1px solid #362B24",
                  outline:"none !important"
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={passwordIcon}
                        alt="password-logo"
                        className="img-fluid"
                        style={{ width: "20px",}}
                      />
                    </InputAdornment>
                  ),
                   endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={showPassword ? OpenEyeIcon : CloseEyeIcon}
                          alt="toggle-password-visibility"
                          onClick={handleClickShowPassword}
                          style={{ width: "20px", cursor: "pointer",opacity:'0.4' }}
                        />
                      </InputAdornment>
                    ),
                  style: { borderRadius: "28px" },
                }}
                
              />
            </Box>

            <Box
              sx={{
                marginRight: { xs: "25px", sm: "35px", md: "35px" },
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Typography
                sx={{
                  color: "#065F46",
                  fontFamily: "Heebo",
                  fontSize: "17px",
                  fontWeight: "700",
                  textAlign: "right",
                  cursor: "pointer",
                }}
                onClick={handleForgotPasswordClickOpen}
              >
                Forgot Password?
              </Typography>
            </Box>

            <Box
              sx={{
                padding: "12px",
                width: "90%",
                marginTop: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
           <CustomBtn onClick={handleSignIn} text="Sign in" disable={btnDisabale} />

            </Box>
          </CardContent>
        </StyledCard>
      </Box>

      <ForgotPassword
        open={openForgotPassword}
        onClose={handleForgotPasswordClose}
        onNext={handleOtpClickOpen}
      />

      <OtpModal
        open={openOtp}
        onClose={handleOtpClose}
        onNext={handleNewPasswordClickOpen}
        onBack={handleBackToForgotPassword}
        otp={otp}
        setOtp={setOtp}
        refId={refId}
      />

      <ConfirmPassModal
        open={openNewPassword}
        onClose={handleNewPasswordClose}
        onSubmit={handleSubmitNewPassword}
        onBack={handleBackToOtp}
        refId={refId}
      />
    </div>
  );
};

const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: "480px",
  borderRadius: "32px",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    minHeight: "auto",
  },
}));

export default SignUp;
